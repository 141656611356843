.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #FCEAE9;
  font-family: 'Open Sans', sans-serif; 
  font-size: 16px;
  font-weight: 600;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
